<template>
  <div>
    <el-input disabled v-model="showValue">
      <template slot="append">
        <el-upload
            ref="uploader"
            :action="action"
            :headers="{ytoken:vuex_token.data}"
            name="file"
            :show-file-list="false"
            accept=".pem"
            :data="{target:modeTarget}"
            :on-success="success"
            :on-error="error"
            :limit="1"
        >
          <el-button type="primary">选择证书文件</el-button>
        </el-upload>
      </template>
    </el-input>
  </div>
</template>

<script>


import {requestWithUni} from "@/common/resquest";

export default {
  name: "y_upload_cert",
  model:{
    prop:"modelval",
    event:'change'
  },
  props:{
    mode:{
      type:String,
      default: "cert",
      required:true,
      validator:(value)=>{
        return ["cert","key"].includes(value);
      }
    },
    modelval: {
      type:Boolean,
      default:false
    }
  },
  watch:{
    modelval(){
      this.value = this.modelval;
    },
    value(e){
      this.$emit('change',e);
    }
  },
  data() {
    return {
      action:"",
      value: this.modelval,
    }
  },
  mounted() {
    this.action = requestWithUni(this.axios.defaults.baseURL +  this.$u.api.attach.wxPayPemAction);
  },
  computed:{
    showValue(){
      return this.modelval ? '已上传' : "未上传";
    },
    modeTarget(){
      return this.mode === "cert" ? "apiclient_cert.pem" :"apiclient_key.pem";
    },
  },
  methods:{
    success(){
      this.$refs.uploader.clearFiles();
      this.$message.success("上传成功");
      this.$emit("load")
    },
    error(e){
      console.log(e)
    },
    handleClose(done){
      done();
    },
  }
}
</script>
<style scoped>

</style>
<style>
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
}
</style>